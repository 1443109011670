import { InteractionStatus } from "@azure/msal-browser"
import { loginRequest } from "./authConfig"
import DebugLogger from "../Common/Debug"

async function aquireAccessToken(account, inProgress, instance) {
  let accessToken = ""
  if (account && inProgress === InteractionStatus.None) {
    await instance
      .acquireTokenSilent({
        ...loginRequest,
        account: account,
      })
      .then(async response => {
        accessToken = response.accessToken
        // return response.accessToken
      })
      .catch(async error => {
        DebugLogger(
          "AuthToken",
          "Silent token acquisition fails. Acquiring token using redirect" +
            error
        )
        // fallback to interaction when silent call fails
        return instance.acquireTokenRedirect(loginRequest)
      })
  }

  return accessToken
}

export const authToken = async (account, inProgress, instance) => {
  return await aquireAccessToken(account, inProgress, instance)
}
